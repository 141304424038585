import React from "react"
import { Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Background from "../components/background"
import BgImage from "../images/races-background.jpg"


const RacePage = ({data}) => (
  <Layout>
    <SEO title="Upcoming Races" />
    <RaceWrapper>
    <h1>Upcoming Races</h1>
    {/* </RaceWrapper>
    <RaceWrapper> */}
    <DescWrapper>
      Sorry, no races at this time.
    /* {data.allMarkdownRemark.edges.map(post => (
        <DescDiv key={ post.node.id }>
            <h3>{ post.node.frontmatter.title }</h3>
            <p>{post.node.frontmatter.desc.slice(0,200)}...</p>
            <small>Race Date: { post.node.frontmatter.date }</small>
            <br />
            <br />
            <Link to={post.node.frontmatter.path}>I want to run this!</Link>
        </DescDiv>
    ))} */
    </DescWrapper>
    </RaceWrapper>
  </Layout>
)

export const pageQuery = graphql`
    query RaceIndexQuery {
        allMarkdownRemark (
            filter: { frontmatter: { topic: { eq: "Races" }}}
            sort: { order: ASC, fields: [frontmatter___date] }
            ){
            edges {
                node {
                    id
                    frontmatter {
                        path
                        title
                        date
                        author
                        topic
                        desc
                    }
                }
            }
        }
        
    } 
`

export default RacePage

const RaceWrapper = styled.div`
   
  background-image: {BgImage};
  display: grid; 
  text-align: center;
  h1 {
      font-family: 'Cairo', sans-serif;
      font-weight: 900;
  }
  background-color: #f5deb3; 
`

const DescWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin: 150px;
    text-align: left;
    div:hover {
        box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.2), 0 16px 34px 0 rgba(0, 0, 0, 0.19);
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        margin-left: 20px;
        margin-right: 20px;
        
        .display-1 {
          font-size: 2.4rem;
          text-align: left;
        }
      }
`

const DescDiv = styled.div`
    padding: 0.2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #f0f0f0;
    a {
        color: black;
    }
    a:hover {
        color: #15072B;
        text-decoration: underline;
    }
    h2 {
        color: #15072B;
    }
`
